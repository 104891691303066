import { createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState, AppThunkAction } from '@ducks/index';
import { APIDeliveryGeoTextResponse } from '@api/types/delivery/geoText';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { cartSelector, getCartItems, deleteCart } from '@ducks/cart';
import { CountryItem, geoActions, geoSelector, getCountries } from '@ducks/geo';
import { APIDeliveryValidateResponse } from '@api/types/delivery/validate';
import { APIOrderOptionsResponse } from '@api/types/order/options';
import { compact, keyBy, get } from 'lodash';
import { getDays } from '@src/utils/getPlural';
import { getCartPage, cartPageActions, cartPageSelector } from '../cart';
import Router from 'next/router';
import { APIOrdersResponse } from '@api/types/orders/orders';
// import { getTokenFromEmail } from '@utils/crypto';
import { shopSettingsSelector } from '@ducks/application/shopSettings';
import { ObjectWithKeyAsValue } from '@src/utils/utils';
import { CONTEXT } from '@vsemayki/url-resolver';
import { loadingActions, loadingSelector } from '@ducks/application/loading';
import { Data } from '@api/types/cart';
import * as validators from './validators';
import { phoneMasks } from '@src/utils/phoneMask';
import { isStringEqualRussia } from '@utils/helpers';
import { FormRegExs } from '@components/CartDelivery/Form/utils';

const { apiRequest } = ApiMiddleware;

export const MAX_ORDER_SUM_POSTAL = 4000 as const;

type DeliveryGroupsKeys = 'PICKUP' | 'RUSSIAN_POST' | 'DELIVERY24' | 'COURIER';
const deliveryGroups: ObjectWithKeyAsValue<DeliveryGroupsKeys> = {
    PICKUP: 'PICKUP',
    COURIER: 'COURIER',
    DELIVERY24: 'DELIVERY24',
    RUSSIAN_POST: 'RUSSIAN_POST',
};

export enum PaymentsAlias {
    POSTAL = 'POSTAL',
    PREPAY = 'PREPAY',
    PREPAY_SBP = 'PREPAY_SBP',
}

export type FormCartDeliveryDataType = {
    countryTitle: string;
    cityTitle: string;
    cityRegion: string;
    userName: string;
    address: string;
    deliveryPoint: string;
    postcode: string;
    phone: string;
    email: string;
};

type CityItem = { id: string; title?: string; region: string; prefix: string };

type ErrorsCartDeliveryPage = {
    [key: string]: ErrorsCartDeliveryPageData;
};

type ErrorsCartDeliveryPageData = {
    field: string | null;
    message: string;
};

export type CartDeliveryPageState = {
    geo: {
        city: CityItem;
        region: string;
        country: CountryItem;
        cityTrusted: boolean;
    };
    user: {
        name: string;
        phone: string;
        email: string;
    };
    address: string;
    addressPickup: CartDeliveryPageState['address'];
    delivery: APIOrderOptionsResponse['deliveries'][0] & {
        point: APIOrderOptionsResponse['deliveries'][0]['pickup_point_ids'][0];
    };
    comment: string;
    group: string | null;
    corporate: boolean;
    //TODO: add typing below
    // eslint-disable-next-line @typescript-eslint/ban-types
    requisites: {};
    deliveries: APIOrderOptionsResponse['deliveries'];
    postamats: {
        alias: string;
        title: string;
        color: string;
        hints: string[];
        points: {
            address: string;
            allowed_payments: number[];
            content: string;
            coords: [number, number];
            cost: number;
            delivery: string;
            description: string;
            id: string;
            services: string[];
        }[];
    }[];
    groupDeliveries: CartDeliveryPageState['deliveries'];
    groups: APIOrderOptionsResponse['groups'];
    allowedPayments: number[];
    payments: APIOrderOptionsResponse['payments'];
    payment: string | null;
    cities: CityItem[];

    is_subscribe_accepted: boolean;
    manager_id: string;
    bannedDesign: APIDeliveryValidateResponse['additionalData'];

    isLoading: boolean;
    isSubmitting: boolean;
    errors: ErrorsCartDeliveryPage;
    error: string | null;
    errorAddOrder: string | null;
    forceValidate: boolean;
    selectedPoint: CartDeliveryPageState['postamats'][0]['points'][0] | null;
    postcode: string;
    rules: boolean;
    orderTrackingId: APIOrdersResponse['id'];
    initialLoaded: boolean;
};

const errorsRequiredArray = [
    'countryTitle',
    'cityTitle',
    'cityRegion',
    'userName',
    'address',
    'deliveryPoint',
    'postcode',
];

const errorsRequiredSpecArray = ['phone', 'email'];

const validationsKeyCheck = {
    countryTitle: 'geo.country.title',
    cityTitle: 'geo.city.title',
    cityRegion: 'geo.region',
    userName: 'user.name',
    address: 'address',
    deliveryPoint: 'delivery.alias',
    postcode: 'postcode',
    phone: 'user.phone',
    email: 'user.email',
};

const deliveryPickup = ['deliveryPoint'];
const deliveryNotPickup = ['address'];
const deliveryIsCheckPostCode = ['postcode'];

export const initialState: CartDeliveryPageState = {
    geo: {
        city: { id: '', prefix: '', region: '', title: '' },
        region: '',
        country: {
            active: false,
            allow_russian: false,
            id: 0,
            iso_alphabetic: '',
            iso_numeric: 0,
            name: '',
            name_alt: null,
            name_en: null,
            name_official: null,
            position: 0,
            split_type: null,
            suggest: [],
            title: '',
        },
        cityTrusted: true,
    },
    user: {
        name: '',
        phone: '',
        email: '',
    },
    address: '',
    addressPickup: '',
    delivery: {
        alias: '',
        allowed_payments: [],
        brand_alias: '',
        cost: 0,
        description: '',
        duration: { max: 0, type: '' },
        group: '',
        id: 0,
        pickup_point_ids: [],
        point: '',
        position: 0,
        productions: { alias: '', max: 0, min: 0 },
        store_key: '',
        title: '',
        pickup_points: [],
    },
    comment: process.env.NODE_ENV === 'development' ? 'api-test-order' : '',
    errors: {},
    group: null,
    corporate: false,
    requisites: {},
    deliveries: [],
    postamats: [],
    groupDeliveries: [],
    groups: [],
    allowedPayments: [],
    payments: [],
    payment: 'PREPAY',
    cities: [],

    is_subscribe_accepted: false,
    manager_id: '',
    bannedDesign: [],

    isLoading: true,
    isSubmitting: false,

    error: null, // global error
    errorAddOrder: null,
    forceValidate: false,
    selectedPoint: null,
    postcode: '',
    rules: true,
    orderTrackingId: 0,
    initialLoaded: false,
};

const cartDeliveryPage = createSlice({
    initialState,
    name: 'CartDeliveryPage',
    reducers: {
        setCities: (
            state,
            actions: PayloadAction<CartDeliveryPageState['cities']>
        ) => {
            state.cities = actions.payload;
        },
        setCity: (
            state,
            action: PayloadAction<CartDeliveryPageState['geo']['city']>
        ) => {
            state.geo.city = action.payload;
        },
        setCountryTitle: (state, action: PayloadAction<string>) => {
            state.geo.country.title = action.payload;
        },
        setCityTitle: (state, action: PayloadAction<string>) => {
            state.geo.city.title = action.payload;
        },
        setRegion: (
            state,
            action: PayloadAction<CartDeliveryPageState['geo']['region']>
        ) => {
            state.geo.region = action.payload;
        },
        setPostCode: (state, action: PayloadAction<string>) => {
            state.postcode = action.payload;
        },
        setComment: (state, action: PayloadAction<string>) => {
            state.comment = action.payload;
        },
        setSubmitting: (
            state,
            action: PayloadAction<CartDeliveryPageState['isSubmitting']>
        ) => {
            state.isSubmitting = action.payload;
        },
        setBannedDesigns: (
            state,
            action: PayloadAction<CartDeliveryPageState['bannedDesign']>
        ) => {
            state.bannedDesign = action.payload;
        },
        setDefaultAddress: (
            state,
            action: PayloadAction<CartDeliveryPageState['geo']>
        ) => {
            state.geo = action.payload;
        },
        setDefaultDelivery: (state) => {
            const { deliveries, groupDeliveries } = state;
            if (deliveries.length > 0) {
                const delivery = deliveries[0];
                const { group } = delivery;
                state.group = group;
            } else {
                state.group = null;
            }

            if (groupDeliveries.length === 1) {
                const [delivery] = groupDeliveries;
                state.delivery = { ...state.delivery, ...delivery };
            } else {
                state.delivery = initialState.delivery;
            }
        },
        setPickupPoint: (
            state,
            action: PayloadAction<CartDeliveryPageState['selectedPoint']>
        ) => {
            state.selectedPoint = action.payload;
        },
        setDeliveryCost: (
            state,
            action: PayloadAction<CartDeliveryPageState['delivery']['cost']>
        ) => {
            state.delivery.cost = action.payload;
        },
        setDelivery: (
            state,
            action: PayloadAction<CartDeliveryPageState['delivery']>
        ) => {
            state.delivery = action.payload;
        },
        setDeliveries: (
            state,
            action: PayloadAction<CartDeliveryPageState['deliveries']>
        ) => {
            state.deliveries = action.payload;
        },
        setAllowedPayments: (
            state,
            action: PayloadAction<CartDeliveryPageState['allowedPayments']>
        ) => {
            const { payments, payment } = state;

            state.allowedPayments = action.payload;

            const paymentToSet = payments.find((p) => p.alias === payment);

            let newPayment = null;

            if (paymentToSet) {
                newPayment = paymentToSet.alias;
            } else if (payments.length > 0) {
                newPayment = payments[0].alias;
            }

            state.payment = newPayment;
        },
        changeFields: (
            state,
            action: PayloadAction<Partial<CartDeliveryPageState>>
        ) => {
            return { ...state, ...action.payload };
        },
        setError: (
            state,
            action: PayloadAction<CartDeliveryPageState['error']>
        ) => {
            state.error = action.payload;
        },
        setErrorAddOrder: (
            state,
            action: PayloadAction<CartDeliveryPageState['errorAddOrder']>
        ) => {
            state.errorAddOrder = action.payload;
        },
        setErros: (state, action: PayloadAction<ErrorsCartDeliveryPage>) => {
            state.errors = action.payload;
        },
        setForceValidation: (
            state,
            action: PayloadAction<CartDeliveryPageState['forceValidate']>
        ) => {
            state.forceValidate = action.payload;
        },
        setIsLoading: (
            state,
            action: PayloadAction<CartDeliveryPageState['isLoading']>
        ) => {
            state.isLoading = action.payload;
        },
        setPostomats: (
            state,
            action: PayloadAction<CartDeliveryPageState['postamats']>
        ) => {
            state.postamats = action.payload;
        },
        setRules: (
            state,
            action: PayloadAction<CartDeliveryPageState['rules']>
        ) => {
            state.rules = action.payload;
        },
        setAddress: (
            state,
            action: PayloadAction<CartDeliveryPageState['address']>
        ) => {
            state.address = action.payload;
        },
        setAddressPickup: (
            state,
            action: PayloadAction<CartDeliveryPageState['address']>
        ) => {
            state.addressPickup = action.payload;
        },
        setGroup: (
            state,
            action: PayloadAction<CartDeliveryPageState['group']>
        ) => {
            state.group = action.payload;
            state.delivery = initialState.delivery;
            state.selectedPoint = initialState.selectedPoint;
        },
        setPayment: (
            state,
            action: PayloadAction<CartDeliveryPageState['payment']>
        ) => {
            state.payment = action.payload;
        },
        setOrderTrackingId: (
            state,
            action: PayloadAction<CartDeliveryPageState['orderTrackingId']>
        ) => {
            state.orderTrackingId = action.payload;
        },
        setCountry: (
            state,
            action: PayloadAction<CartDeliveryPageState['geo']['country']>
        ) => {
            state.geo.country = action.payload;
        },
        setUser: (
            state,
            action: PayloadAction<CartDeliveryPageState['user']>
        ) => {
            state.user = action.payload;
        },
        setInitialLoaded: (
            state,
            action: PayloadAction<CartDeliveryPageState['initialLoaded']>
        ) => {
            state.initialLoaded = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(geoActions.setCity, (state, { payload }) => {
                state.geo.city = { ...initialState.geo.city, title: payload };
            })
            .addCase(geoActions.setCountry, (state, { payload }) => {
                state.geo.country = payload;
            })
            .addCase(geoActions.setRegion, (state, { payload }) => {
                state.geo.city.region = payload;
                state.geo.region = payload;
            }),
});

export const cartDeliveryPageActions = cartDeliveryPage.actions;

export default cartDeliveryPage.reducer;

export const cartDeliveryPageSelector = (state: RootState) =>
    state.pages.cartDelivery;

export const cartDeliveryErrorsSelector = (state: RootState) =>
    state.pages.cartDelivery.errors;

export const cartDeliveryPageErrorAddOrder = (state: RootState) =>
    state.pages.cartDelivery.errorAddOrder;

export const citySuggest: AppThunkAction<string> = (city) => async (
    dispatch
) => {
    if (city.length === 0) {
        dispatch(cartDeliveryPageActions.setCities([]));
        return;
    }

    try {
        const citiesSuggestionsResponse = await dispatch<
            APIDeliveryGeoTextResponse
        >(apiRequest({ url: `/rest/delivery/geo?text=${city}` }));

        const citiesSuggestions = citiesSuggestionsResponse.map((item) => {
            return {
                id: item.info.uid,
                title: item.match,
                region: item.info.region,
                prefix: item.type.short,
            };
        });

        dispatch(cartDeliveryPageActions.setCities(citiesSuggestions));
    } catch (e) {
        console.error('[checkout:city_suggest:fail] ', e.toString());
        dispatch(cartDeliveryPageActions.setCities([]));
    }
};

export const validateDesigns: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const cart = cartSelector(State);
        const country = cartDeliveryPageSelector(State).geo.country;

        const body = {
            items: cart.items.map((item) => ({
                template_id: item.design,
            })),
            delivery: {
                address: {
                    country: country.title,
                },
            },
        };

        const res = await dispatch<APIDeliveryValidateResponse>(
            apiRequest({
                method: 'POST',
                url: '/rest/proxy-delivery/validate',
                data: body,
            })
        );

        const validDesigns = Array.isArray(res);
        const bannedDesigns = (!Array.isArray(res) && res.additionalData) || [];

        dispatch(
            cartDeliveryPageActions.setBannedDesigns(
                validDesigns ? [] : bannedDesigns
            )
        );
    } catch (error) {
        console.error(error);
    }
};

export const setDefaultAddress: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    const State = getState();
    const GeoState = geoSelector(State);
    const CartDeliveryPageState = cartDeliveryPageSelector(State);
    const countries = GeoState.countries;
    const [defaultCountry] = countries;
    const checkoutGeo = CartDeliveryPageState.geo;

    if (GeoState.showConfirmCity && !GeoState.country) {
        return;
    }

    const country = checkoutGeo.country.title
        ? checkoutGeo.country
        : countries.find(
              (item) =>
                  item.title.toLowerCase() ===
                  GeoState.country.title.toLowerCase()
          ) ?? defaultCountry;

    if (country.title.toLowerCase() === 'россия') {
        const cityName = checkoutGeo.city.title || GeoState.city;
        if (cityName) await dispatch(citySuggest(cityName));

        const cities = cartDeliveryPageSelector(getState()).cities;
        const city: CartDeliveryPageState['geo']['city'] = cities.find(
            (city) => city.title === cityName
        ) ||
            cities[0] || {
                id: -1,
                title: GeoState.city,
                prefix: 'г',
                region: GeoState.region,
            };

        const region: CartDeliveryPageState['geo']['region'] =
            city.region || GeoState.region;

        dispatch(
            cartDeliveryPageActions.setDefaultAddress({
                country,
                city,
                region,
                cityTrusted: true,
            })
        );
    } else {
        const city = checkoutGeo.city || GeoState.city;
        const region = checkoutGeo.region || GeoState.region;

        dispatch(
            cartDeliveryPageActions.setDefaultAddress({
                country,
                city,
                region,
                cityTrusted: true,
            })
        );
    }
};

export const checkSaveDelivery: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    const State = getState();
    const {
        group,
        delivery,
        deliveries,
        groups,
        postamats,
        payments,
    } = cartDeliveryPageSelector(State);
    const checkedGroup = groups.find((item) => item.alias === group);

    if ((!delivery.alias || !checkedGroup) && deliveries.length !== 1) {
        dispatch(cartDeliveryPageActions.setDefaultDelivery());
        if (payments.length === 1) {
            dispatch(cartDeliveryPageActions.setPayment(payments[0].alias));
        }
        return;
    }
    if (payments.length === 1) {
        dispatch(cartDeliveryPageActions.setPayment(payments[0].alias));
    }
    if (group === deliveryGroups.PICKUP) {
        const postamat = compact(
            postamats.map((item) =>
                item.points.find(
                    (point) =>
                        point.id === delivery.point &&
                        point.delivery === delivery.alias
                )
            )
        );

        if (postamat.length === 0) {
            dispatch(cartDeliveryPageActions.setDefaultDelivery());
            return;
        }

        dispatch(cartDeliveryPageActions.setPickupPoint(postamat[0]));
        dispatch(cartDeliveryPageActions.setDeliveryCost(postamat[0].cost));
        dispatch(
            cartDeliveryPageActions.changeFields({
                address: postamat[0].address,
            })
        );
        dispatch(
            cartDeliveryPageActions.setAllowedPayments(
                postamat[0].allowed_payments
            )
        );
        return;
    }
    if (deliveries.length === 1) {
        dispatch(cartDeliveryPageActions.setGroup(deliveries[0].group));

        if (deliveries[0]?.title) {
            dispatch(
                cartDeliveryPageActions.changeFields({
                    delivery: {
                        ...deliveries[0],
                        title: deliveries[0]?.title || '',
                        point: '',
                    },
                    allowedPayments: deliveries[0]?.allowed_payments,
                })
            );
        }
        return;
    }
    const deliveryOther = deliveries.find(
        (item) => item.alias === delivery.alias
    );

    if (!deliveryOther) {
        dispatch(cartDeliveryPageActions.setDefaultDelivery());
        return;
    }
    dispatch(cartDeliveryPageActions.setDeliveryCost(deliveryOther.cost));
    dispatch(
        cartDeliveryPageActions.setAllowedPayments(
            deliveryOther.allowed_payments
        )
    );
};

export const preparePickupPoints: AppThunkAction<{
    points: CartDeliveryPageState['deliveries'];
    payments: CartDeliveryPageState['payments'];
}> = ({ points, payments: payments }) => async (dispatch) => {
    const colors = {
        VSEMAYKI: '#f371d1',
        CDEK: '#064000',
        BOXBERRY: '#FF0000',
        DPD: '#0000FF',
        PICK_POINT: '#FFA500',
    };

    const paymentsKeyed = keyBy(payments, 'alias');

    const resolvePayment = (
        allowedPayments: CartDeliveryPageState['allowedPayments'],
        cashless: boolean
    ) => {
        if (allowedPayments.includes(paymentsKeyed.POSTAL?.id)) {
            return `Наличными ${cashless ? ', банковской картой' : ''}`;
        }

        return 'банковской картой, <span style="color:#F173CD">100% предоплата</span>';
    };

    const result = points.reduce<
        Record<string, CartDeliveryPageState['postamats'][0]>
    >((acc, delivery) => {
        const deliveryText = getDays(delivery.duration.max); //getDurationText([delivery]);

        if (!acc[delivery.brand_alias]) {
            const hints = [
                delivery.cost === 0 ? 'Бесплатно' : `${delivery.cost} руб.`,
            ];
            if (!delivery.allowed_payments.includes(paymentsKeyed.POSTAL?.id)) {
                hints.push('100% предоплата');
            }

            hints.push(deliveryText);

            const title = delivery.brand_alias.replace('_', '');

            acc[delivery.brand_alias] = {
                alias: delivery.brand_alias,
                title:
                    title.charAt(0).toUpperCase() +
                    title.slice(1).toLowerCase(),
                color: get(
                    delivery,
                    'pickup_points.0.delivery_brand_color',
                    colors[delivery.brand_alias as keyof typeof colors]
                ),
                hints,
                points: [],
            };
        }

        const points =
            delivery.pickup_points?.map((point) => {
                const title = delivery.brand_alias
                    .replace('_', '')
                    .toLowerCase();

                let content = '<div>';
                if (point.work_time) {
                    content += `<p>Время работы: ${point.work_time}</p>`;
                }
                const resolvedPayment = resolvePayment(
                    point.allowed_payments,
                    point.cashless
                );
                if (resolvedPayment) {
                    content += `<p>Оплата: ${resolvedPayment}</p>`;
                }
                if (deliveryText) {
                    content += `<p>Доставим: ${deliveryText}</p>`;
                }
                if (point.description) {
                    content += `<p>Описание: ${point.description}</p>`;
                }
                content += '</div>';

                return {
                    id: point.id,
                    delivery: point.delivery_alias,
                    allowed_payments: delivery.allowed_payments,
                    address: point.address,
                    cost: delivery.cost,
                    description:
                        point.type === 'POSTOMAT'
                            ? `Постамат ${title}`
                            : `Пункт выдачи ${title}`,
                    coords: [point.latitude, point.longitude] as [
                        number,
                        number
                    ],
                    content: content,
                    services: [point.type === 'STORE' ? 'PVZ' : point.type],
                };
            }) ?? [];

        acc[delivery.brand_alias].points.push(...points);

        return acc;
    }, {});

    dispatch(cartDeliveryPageActions.setPostomats(Object.values(result)));
};

export const getDeliveries: AppThunkAction<{
    region?: string;
}> = (geoObject = {}) => async (dispatch, getState) => {
    const State = getState();

    const { region: regionFromInput } = geoObject;

    const {
        corporate,
        geo: { city, country, region, cityTrusted },
    } = cartDeliveryPageSelector(State);
    const cart = cartSelector(State);

    const resultRegion = regionFromInput || region;

    let error = null;
    if (
        !resultRegion &&
        (country?.title ?? 'россия').toLowerCase() === 'россия'
    ) {
        error = 'Введите область';
    }

    if (!city || !/[a-zа-яё]/i.test(city.title || '')) {
        error = 'Введите название города';
    }

    if (error) {
        console.error('getDeliveries error :>> ', error);
        if (!cityTrusted) {
            dispatch(cartDeliveryPageActions.setError(error));
            dispatch(cartDeliveryPageActions.setForceValidation(true));
        }

        dispatch(
            cartDeliveryPageActions.changeFields({
                deliveries: [],
                groups: [],
                payments: [],
                allowedPayments: [],
                group: null,
                payment: 'PREPAY',
                delivery: initialState.delivery,
                isLoading: false,
            })
        );
        return;
    }

    dispatch(cartDeliveryPageActions.setError(null));
    dispatch(cartDeliveryPageActions.setIsLoading(true));

    const deliveryOptions = {
        cart: cart.items.map((item) => ({
            design: item.design,
            product_type: item.product,
            count: item.amount,
            size: item.attributes.size,
            color: item.attributes.color,
            type: item.attributes.type || 'p',
        })),
        promo_code: cart.promocode,
        address: {
            city: city.title,
            country: country.title,
            region,
        },
        options: [corporate ? 'is_corporate' : ''].filter(Boolean),
    };

    try {
        const response = await dispatch<APIOrderOptionsResponse>(
            apiRequest({
                method: 'POST',
                url: '/rest/order/options',
                data: deliveryOptions,
            })
        );

        const deliveries = response.deliveries;
        const groups = response.groups.filter(
            (el) => el.alias !== 'RUSSIAN_POST'
        );
        const payments = response.payments;

        if (deliveries.length === 0) {
            dispatch(
                cartDeliveryPageActions.changeFields({
                    error: 'Нет доступных способов доставки.',
                    isLoading: false,
                    deliveries: [],
                    groups: [],
                    payments: [],
                    group: null,
                })
            );

            return;
        }

        const pickupPoints = deliveries.filter(
            (delivery) => delivery.group === deliveryGroups.PICKUP
        );
        dispatch(preparePickupPoints({ points: pickupPoints, payments }));

        dispatch(
            cartDeliveryPageActions.changeFields({
                deliveries,
                groups,
                payments,
                isLoading: false,
            })
        );

        dispatch(checkSaveDelivery());
    } catch (e) {
        dispatch(
            cartDeliveryPageActions.changeFields({
                error: 'Нет доступных способов доставки.',
                isLoading: false,
                deliveries: [],
                groups: [],
                payments: [],
            })
        );

        return;
    }
};

export const initCheckout: AppThunkAction<
    undefined,
    Promise<Data | null>
> = () => async (dispatch, getState) => {
    const State = getState();
    const GeoState = geoSelector(State);
    const CartState = cartSelector(State);

    const promises: Promise<unknown>[] = [];

    if (GeoState.countries.length === 0) {
        promises.push(dispatch(getCountries()));
    }

    if (CartState.items.length === 0) {
        promises.push(dispatch(getCartItems()));
    }

    dispatch(cartDeliveryPageActions.setInitialLoaded(false));

    await Promise.all(promises);
    const cartPageData = await dispatch(getCartPage());

    dispatch(cartDeliveryPageActions.setInitialLoaded(true));

    return cartPageData;
};

export const initCheckoutForms: AppThunkAction = () => async (dispatch) => {
    dispatch({ type: 'INIT_CHECKOUT_FORMS' });
    dispatch(getDeliveries());
    dispatch(validateDesigns());
};

export const createOrder: AppThunkAction = () => async (dispatch, getState) => {
    const State = getState();

    const isValid = await dispatch(validatePreOrder());

    if (isValid) {
        const isAppLoading = loadingSelector(State);
        const isSubmitting = cartDeliveryPageSelector(State).isSubmitting;

        if (isAppLoading || isSubmitting) {
            console.error('createOrder was called second time', {
                isSubmitting,
                isAppLoading,
            });

            return;
        }

        dispatch(cartDeliveryPageActions.setErrorAddOrder(null));
        dispatch(loadingActions.setLoading(true));
        dispatch(cartDeliveryPageActions.setSubmitting(true));

        // const ApplicationState = applicationSelector(State);
        const CheckoutState = cartDeliveryPageSelector(State);
        const CartState = cartSelector(State);
        const ShopSettingsState = shopSettingsSelector(State);
        const shopId = shopSettingsSelector(State).id;

        const { items: cartItems } = cartSelector(State);

        // const {
        //     application,
        //     checkout,
        //     cart: { items: cartItems }
        // } = getState();

        // const COOKIE_SHORT = 'ref_storage_short';
        // const COOKIE_LONG = 'ref_storage_long';
        // const refStorage =
        //     Cookies.getJSON(COOKIE_SHORT) || Cookies.getJSON(COOKIE_LONG) || {};

        // const partner = {
        //     id: parseInt(refStorage.value),
        //     type: refStorage.type
        // };

        const utmTags = {
            utm_source: shopId,
            utm_medium: 'shop',
        };

        // // admitad integration
        // const lastClick = Cookies.get('last_click_cpa') || 0;
        // const admitad_uid = Cookies.get('admitad_uid') || 0;
        // if (parseInt(lastClick) === 4899757) {
        //     analytics['admitad_uid'] = admitad_uid;
        // }

        const cart = cartItems.map((item) => ({
            design: item.design,
            product_type: item.product,
            count: item.amount,
            price: item.price,
            size: item.attributes.size,
            color: item.attributes.color,
            type: item.attributes.type === 'c' ? 'constructor' : 'product',
        }));

        const address = {
            country: CheckoutState.geo.country.title,
            region: CheckoutState.geo.region,
            city: CheckoutState.geo.city.title,
            address:
                CheckoutState.group === deliveryGroups.PICKUP
                    ? CheckoutState.addressPickup
                    : CheckoutState.address,
            postcode: CheckoutState.postcode,
        };

        const receiver = {
            comment: CheckoutState.comment,
            name: CheckoutState.user.name,
            phone: CheckoutState.user.phone.replace(/[\s()+-]/g, ''),
            email: CheckoutState.user.email,
            address,
        };

        const sendData = {
            cart: cart,
            promo_code: CartState.promocode,
            payment_alias: CheckoutState.payment,
            delivery: CheckoutState.delivery.alias,
            pickup_point_id: CheckoutState.delivery.point,
            receiver,
            partner: {
                id: ShopSettingsState.subaccountId ?? 245336,
                type: 'ref',
                salt: '',
                domain: location.hostname,
                is_shop: true,
            },
            meta: { analytics: utmTags },
            client: {
                meta: { analytics: utmTags },
            },
            requisites: CheckoutState.corporate
                ? CheckoutState.requisites
                : undefined,
        };

        try {
            const order = await dispatch<APIOrdersResponse>(
                apiRequest({
                    method: 'POST',
                    url: '/rest/orders',
                    data: sendData,
                })
            );

            if (!order.id) {
                throw new Error(
                    `No order id, order:${JSON.stringify(order, null, 2)}`
                );
            }
            // dispatch(setPresentCookie(CheckoutState.user.email));

            //TODO: commented by p.deneschik for being unused and can generate an error, need to decide do we need it or not;
            // const token = getTokenFromEmail(
            //     CheckoutState.user.email,
            //     order.id.toString()
            // );

            let cartSum = 0;

            const gtmItems = State.pages.cart.items.map((item) => {
                cartSum += item.amount * item.price;

                return {
                    name: item.title,
                    id: `${item.design}_${item.product.tkey}`,
                    price: item.price,
                    brand: 'Vsemayki',
                    category: item.product.title,
                    variant: item.attributes.color,
                    quantity: item.amount,
                    coupon: CartState.promocode,
                };
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    purchase: {
                        actionField: {
                            id: order.id,
                            affiliation:
                                State.application.shopSettings.title ||
                                'Vsemayki',
                            revenue:
                                cartSum +
                                State.pages.cartDelivery.delivery.cost,
                            shipping: State.pages.cartDelivery.delivery.cost,
                            coupon: CartState.promocode,
                        },
                        products: gtmItems,
                    },
                },
                event: 'pixel-mg-event',
                'pixel-mg-event-category': 'Enhanced Ecommerce',
                'pixel-mg-event-action': 'Purchase',
                'pixel-mg-event-non-interaction': 'False',
            });

            await dispatch(deleteCart()).catch(console.error);
            dispatch(cartPageActions.setInitialValues());
            // dispatch(getCartItems());

            // if (order.auth_token) {
            //     await dispatch(loadUser(order.auth_token));
            // }

            dispatch(cartDeliveryPageActions.setOrderTrackingId(order.id));

            if (CheckoutState.payment === 'PREPAY') {
                window.dataLayer.push({ event: 'payment_click_shop' });
                location.replace(
                    `https://www.vsemayki.ru/payment?id=${order.id}`
                );
            } else {
                await Router.push(
                    {
                        pathname: `/${CONTEXT.orderSuccess}`,
                        query: {
                            order_id: order.id,
                            email: CheckoutState.user.email,
                        },
                    },
                    `/order/success?order_id=${order.id}&email=${CheckoutState.user.email}`
                );
                //Unblock submit button after we route user to new page;
                dispatch(cartDeliveryPageActions.setSubmitting(false));
            }
        } catch (error) {
            console.error('cartDelivery::createOrder', error);
            // alert('При оформлении заказа произошла ошибка');
            dispatch(
                cartDeliveryPageActions.setErrorAddOrder(
                    'При оформлении заказа произошла ошибка'
                )
            );
            //Block submit button till we get server error
            dispatch(cartDeliveryPageActions.setSubmitting(false));
        } finally {
            dispatch(loadingActions.setLoading(false));
        }
    }
};

export const validatePreOrder = (): ThunkAction<
    Promise<boolean>,
    RootState,
    unknown,
    any
> => async (dispatch, getState) => {
    const State = getState();

    const cartDeliveryPage = cartDeliveryPageSelector(State);
    const { order_sum } = cartPageSelector(State);

    if (
        cartDeliveryPage?.payment === PaymentsAlias.POSTAL &&
        order_sum > MAX_ORDER_SUM_POSTAL
    ) {
        dispatch(
            cartDeliveryPageActions.setErrorAddOrder(
                'Постоплата недоступна при заказе больше 4000 рублей'
            )
        );
        return false;
    }

    dispatch(cartDeliveryPageActions.setErros({}));

    const deliveryIsPickup = cartDeliveryPage.group === 'PICKUP';
    const isCountryRussia = isStringEqualRussia(
        cartDeliveryPage.geo.country?.title
    );

    let resultErrorsRequiredArray = [
        ...errorsRequiredArray,
        ...errorsRequiredSpecArray,
    ];

    if (deliveryIsPickup) {
        resultErrorsRequiredArray = resultErrorsRequiredArray.filter(
            (field) => !deliveryNotPickup.includes(field)
        );
    } else {
        resultErrorsRequiredArray = resultErrorsRequiredArray.filter(
            (field) => !deliveryPickup.includes(field)
        );
    }

    if (deliveryIsPickup || isCountryRussia) {
        resultErrorsRequiredArray = resultErrorsRequiredArray.filter(
            (field) => !deliveryIsCheckPostCode.includes(field)
        );
    }

    resultErrorsRequiredArray.forEach((field) => {
        dispatch(
            validateRealTime({
                field,
                value: get(
                    cartDeliveryPage,
                    validationsKeyCheck?.[
                        field as keyof FormCartDeliveryDataType
                    ]
                ),
            } as {
                field: keyof FormCartDeliveryDataType;
                value: string;
            })
        );
    });

    const errors = getState().pages.cartDelivery.errors;

    if (Object.values(errors).filter(Boolean).length) {
        dispatch(
            cartDeliveryPageActions.setErrorAddOrder(
                'Заполните все обязательные поля'
            )
        );
        return false;
    }

    return true;
};

export const validateRealTime: AppThunkAction<{
    field: keyof FormCartDeliveryDataType;
    value: string;
}> = ({ field, value }) => async (dispatch, getState) => {
    const State = getState();

    const cartDeliveryPage = cartDeliveryPageSelector(State);
    const errors = { ...cartDeliveryErrorsSelector(State) };

    const currentCountry = cartDeliveryPage.geo.country.title.toLowerCase();
    const onlyCyrillicAllowed = cartDeliveryPage.geo.country.allow_russian;

    if (errorsRequiredArray.includes(field)) {
        const required = validators.required(value);
        if (!required) {
            errors[field] = {
                field,
                message: validators.texts.required?.[field] || '',
            };

            if (
                field === 'cityTitle' &&
                !isStringEqualRussia(cartDeliveryPage.geo.country.title)
            ) {
                errors[field].message =
                    validators.texts.required?.cityTitleNoRussia;
            }
        } else if (errors[field]) {
            delete errors[field];
        }

        if ((field === 'cityTitle' || field === 'cityRegion') && required) {
            const regEx = onlyCyrillicAllowed
                ? FormRegExs.Cyrillic
                : FormRegExs.Latin;

            if (!value?.match(regEx)) {
                const keyText = onlyCyrillicAllowed ? 'cyrillic' : 'latin';

                errors[field] = {
                    field,
                    message: validators.texts.cyrillicAllowed?.[keyText] || '',
                };
            }
        }
    }

    if (field === 'email') {
        const required = validators.required(value);
        const email = validators.email(value);
        if (!required) {
            errors.email = { field, message: validators.texts.required.email };
        } else if (!email) {
            errors.email = { field, message: validators.texts.notValid.email };
        } else if (errors.email) {
            delete errors[field];
        }
    }

    if (field === 'phone') {
        const normalizedValue = value
            .replace(/_/g, '') // Удаляем символы-заполнители
            .replace(/-+/g, '-') // Убираем лишние дефисы
            .trim(); // Убираем лишние пробелы

        const required = validators.required(normalizedValue);
        const phone = validators.phone(
            normalizedValue,
            currentCountry in phoneMasks
                ? phoneMasks[currentCountry]
                : String.raw`^[0-9\+\-]+$`
        );

        if (!required) {
            errors.phone = { field, message: validators.texts.required.phone };
        } else if (!phone) {
            errors.phone = { field, message: validators.texts.notValid.phone };
        } else if (errors.phone) {
            delete errors[field];
        }
    }

    dispatch(cartDeliveryPageActions.setErros({ ...errors }));
};
